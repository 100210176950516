import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from '../../global-context';
import { makeStyles, Container, Typography, Button, Grid, Chip, Paper } from '@material-ui/core';
import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';
import Loading from '../utils/Loading';
import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';
import { ExportToCsv } from 'export-to-csv';
import fragments from '../utils/graphql/fragments';
import axios from 'axios';

const log = false;

const titleCase = str => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      if (word.length > 0) {
        return word.replace(word[0], word[0].toUpperCase());
      } else {
        return '';
      }
    })
    .join(' ');
};

const defaultOrder = `asc`;
const defaultOrderBy = `EVENT_NAME`;

function InboundReport(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRowId, setExpandedRowId] = useState(0);

  const applyFilters = data => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(o => {
        if (
          (o.event_name && o.event_name.toLocaleLowerCase().includes(search)) ||
          (o.id && (o.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_address1 && (o.shipping_address1 + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_address2 && (o.shipping_address2 + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_city && (o.shipping_city + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_state && (o.shipping_state + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_zip && (o.shipping_zip + ``).toLocaleLowerCase().includes(search)) ||
          (o.outbound_tracking && (o.outbound_tracking + ``).toLocaleLowerCase().includes(search)) ||
          (o.inbound_tracking && (o.inbound_tracking + ``).toLocaleLowerCase().includes(search)) ||
          (o.email && (o.email + ``).toLocaleLowerCase().includes(search)) ||
          (o.first_name && (o.first_name + ``).toLocaleLowerCase().includes(search)) ||
          (o.last_name && (o.last_name + ``).toLocaleLowerCase().includes(search)) ||
          (o.extra_tracking && (o.extra_tracking + ``).toLocaleLowerCase().includes(search)) ||
          (o.booth_id && (o.booth_id + ``).toLocaleLowerCase().includes(search))
        ) {
          return true;
        } else return false;
      });
    }
  };

  const generateCSV = orders => {
    const createCsvRow = order => {
      return {
        EVENT_NAME: order.event_name ? order.event_name : `-`,
        LOCATION: `${order.shipping_city}, ${order.shipping_state}`,
        ID: order.id,
        STATUS: order.status,
        TRANSIT_DAYS: order.transit_days,
        EVENT_DATE: order.event_date ? moment.utc(moment(order.event_date)).format(`YYYY-MM-DD`) : `-`,
        RETURN_DATE: order.return_date ? moment.utc(moment(order.return_date)).format(`YYYY-MM-DD`) : `-`,
        BOOTH_ID: order.booth_id,
        INBOUND_TRACKING: order.inbound_tracking,
        OUTBOUND_TRACKING: order.outbound_tracking,
      };
    };
    const csvRows = orders.map(order => createCsvRow(order));
    const csvOptions = {
      filename: `BBM_orders_returning`,
      showTitle: true,
      title: `BBM orders returning`,
      useKeysAsHeaders: true,
    };

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  const goToOrder = orderId => {
    props.history.push(`/orders/${orderId}`);
  };

  const getTableActions = orders => {
    return [{ label: `Generate\xa0CSV`, handler: () => generateCSV(orders) }];
  };
  const getRowActions = order => {
    return [{ label: `View\xa0Order\xa0Details`, handler: () => goToOrder(order.id) }];
  };
  const sendInboundReminder = async order => {
    try {
      const res = await axios.post('/.netlify/functions/send-late-booth-reminder-email', order);
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  const statusColors = {
    Packing: '#dc3545',
    Inbound: '#17a2b8',
    Shipped: '#17a2b8',
    Delivered: '#6e757c',
    Pending: '#ffc107',
    Cancelled: '#dc3545',
    Returned: '#28a745',
  };

  return (
    <>
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Typography className={cls.headTxt}>Inbound Report</Typography>

          <Subscription
            subscription={GET_ORDERS()}
            onError={err => ctx.handleNotifications(true, `error`, `Failed to retrieve orders: ${err.toString()}`)}
          >
            {/*  @ts-ignore */}
            {({ loading, error, data }) => {
              if (loading) return <Loading fixed />;
              if (error) {
                console.log(`Failed to retrieve orders:`, error);
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>ERROR FINDING ORDERS</Typography>
                  </div>
                );
              }
              if (data && data.orders && data.orders.length > 0) {
                log && console.log(`Orders for orders index:`, data.orders);
                const filteredOrders = applyFilters(data.orders);
                const headers = [
                  { id: `ID`, align: 'center', numeric: true, label: `#` },
                  { id: `EVENT_NAME`, align: 'center', numeric: false, label: `Name` },
                  { id: `STATUS`, align: 'center', numeric: false, label: `Status` },
                  { id: `EVENT_DATE`, align: 'center', numeric: false, label: `Event Date` },
                  { id: `DAYS_LATE`, align: 'center', numeric: false, label: `Days Late` },
                  { id: `RETURN_SHIP_DATE`, align: 'center', numeric: false, label: `Return Ship Date` },
                  { id: `RETURN_DATE`, align: 'center', numeric: false, label: `Return Arrival Date` },
                  { id: `INBOUND_SHIPMENT_STATUS`, align: 'center', numeric: false, label: `Inbound Shipment Status` },
                ];
                const rows = filteredOrders.map(order => {
                  let plannedReturnShipDate;
                  let scheduledArrivalDate;
                  let statusDescription;
                  let lastLocation;
                  let lastChecked;
                  const inboundShipmentAnalysis =
                    ctx.BoothByMail.ups.shipmentAnalysis(order, 'inbound_shipment_activity') || {};
                  console.log(order.id, order, inboundShipmentAnalysis, order.inbound_shipment_activity);

                  console.log(`${order.id} inboundShipmentAnalysis:`, inboundShipmentAnalysis);

                  try {
                    lastChecked = order.inbound_shipment_activity.updatedat ?? null;
                    if (lastChecked) {
                      lastChecked = moment.utc(lastChecked).fromNow();
                    } else {
                      lastChecked = 'bad data';
                    }

                    if (
                      ['SDD', 'RDD'].includes(
                        order?.inbound_shipment_activity?.jsonb?.shipment[0]?.package[0]?.deliveryDate[0]?.type
                      )
                    ) {
                      scheduledArrivalDate = moment.utc(scheduledArrivalDate).format('YYYY-MM-DD');
                    } else {
                      scheduledArrivalDate = null;
                    }

                    plannedReturnShipDate =
                      order?.inbound_shipment_activity?.jsonb?.shipment[0]?.package[0]?.deliveryDate[0]?.date;
                    if (plannedReturnShipDate) {
                      console.log(
                        `${order.id} plannedReturnShipDate:`,
                        order?.inbound_shipment_activity?.jsonb?.shipment[0]?.package[0]?.deliveryDate[0]
                      );
                      plannedReturnShipDate = moment.utc(plannedReturnShipDate).format('YYYY-MM-DD');
                    } else {
                      console.log(
                        `${order.id} plannedReturnShipDate:`,
                        order?.inbound_shipment_activity?.jsonb?.shipment[0]?.package[0]?.deliveryDate[0]
                      );
                      plannedReturnShipDate = order.return_ship_date;
                    }

                    statusDescription = inboundShipmentAnalysis?.latestActivity?.status?.description ?? null;

                    lastLocation = inboundShipmentAnalysis?.latestActivity.location.address.city
                      ? inboundShipmentAnalysis?.latestLocation
                      : null;
                  } catch (err) {
                    console.log(err);
                  }
                  return {
                    ID: (
                      <Typography
                        variant='body2'
                        style={{
                          fontWeight: 500,
                          color: '#1976d2',
                          fontSize: '.875rem',
                        }}
                      >
                        {order.id}
                      </Typography>
                    ),
                    EVENT_NAME: order.event_name ? (
                      <Grid container direction='column' spacing={1}>
                        <Grid item>
                          <Typography
                            variant='body2'
                            style={{
                              fontWeight: 500,
                              fontSize: '.875rem',
                            }}
                          >
                            {order.event_name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant='caption'
                            style={{
                              color: '#1976d2',
                              fontSize: '.75rem',
                            }}
                          >
                            {`${titleCase(order.shipping_city)}, ${order.shipping_state}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography variant='body2' style={{ color: '#6c757d' }}>
                        -
                      </Typography>
                    ),
                    STATUS: (
                      <Chip
                        label={order.status}
                        size='small'
                        style={{
                          backgroundColor: statusColors[order.status] || '#6e757c44',
                          color: '#fff',
                          height: '24px',
                          fontSize: '.75rem',
                          fontWeight: 500,
                        }}
                      />
                    ),
                    EVENT_DATE: order.event_date ? (
                      <Typography
                        variant='body2'
                        style={{
                          fontSize: '.875rem',
                          fontWeight: 500,
                        }}
                      >
                        {moment.utc(moment(order.event_date)).format(`YYYY-MM-DD`)}
                      </Typography>
                    ) : (
                      <Typography variant='body2' style={{ color: '#6c757d' }}>
                        -
                      </Typography>
                    ),
                    DAYS_LATE: (
                      <Typography
                        variant='body2'
                        style={{
                          fontSize: '.875rem',
                          fontWeight: 500,
                          color:
                            inboundShipmentAnalysis && inboundShipmentAnalysis.daysLate > 0 ? '#dc3545' : 'inherit',
                        }}
                      >
                        {inboundShipmentAnalysis ? inboundShipmentAnalysis.daysLate : `No data`}
                      </Typography>
                    ),
                    RETURN_SHIP_DATE: order.return_ship_date ? (
                      <Grid container direction='column' spacing={1}>
                        <Grid item>
                          <Chip
                            label={
                              order.inbound_shipment_activity
                                ? order.inbound_shipment_activity.shipped === true
                                  ? `Shipped`
                                  : `Not Shipped`
                                : `No Activity`
                            }
                            size='small'
                            style={{
                              backgroundColor: order.inbound_shipment_activity
                                ? order.inbound_shipment_activity.shipped
                                  ? '#28a745'
                                  : '#dc3545'
                                : '#ffc107',
                              color: '#fff',
                              height: '24px',
                              fontSize: '.75rem',
                              fontWeight: 500,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Grid container alignItems='center' spacing={1}>
                            <Grid item>
                              <Typography
                                variant='caption'
                                style={{
                                  color: '#6c757d',
                                  fontSize: '.75rem',
                                }}
                              >
                                Planned:
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant='caption'
                                style={{
                                  fontSize: '.75rem',
                                }}
                              >
                                {plannedReturnShipDate}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : order?.inbound_shipment_activity?.delivered ? (
                      <Typography
                        variant='body2'
                        style={{
                          fontSize: '.875rem',
                          fontWeight: 500,
                          color: '#28a745',
                        }}
                      >
                        Delivered
                      </Typography>
                    ) : (
                      <Typography variant='body2' style={{ color: '#6c757d' }}>
                        No shipment data
                      </Typography>
                    ),
                    RETURN_DATE: order.return_date ? (
                      <Grid container direction='column' spacing={1}>
                        <Grid item>
                          <Grid container alignItems='center' spacing={1}>
                            <Grid item>
                              <Typography
                                variant='caption'
                                style={{
                                  color: '#6c757d',
                                  fontSize: '.75rem',
                                }}
                              >
                                Planned:
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant='caption'
                                style={{
                                  fontSize: '.75rem',
                                }}
                              >
                                {order.return_date}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems='center' spacing={1}>
                            <Grid item>
                              <Typography
                                variant='caption'
                                style={{
                                  color: '#6c757d',
                                  fontSize: '.75rem',
                                }}
                              >
                                Scheduled:
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant='caption'
                                style={{
                                  fontSize: '.75rem',
                                }}
                              >
                                {scheduledArrivalDate ? scheduledArrivalDate : '-'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography variant='body2' style={{ color: '#6c757d' }}>
                        -
                      </Typography>
                    ),
                    INBOUND_SHIPMENT_STATUS: order.inbound_shipment_activity ? (
                      <Grid container direction='column' spacing={1}>
                        <Grid item>
                          <Paper
                            elevation={0}
                            style={{
                              padding: '4px 8px',
                              backgroundColor: '#f8f9fa',
                              border: '1px solid #e9ecef',
                              borderRadius: '4px',
                            }}
                          >
                            <Grid container alignItems='center' spacing={1}>
                              <Grid item xs>
                                <Typography
                                  variant='body2'
                                  style={{
                                    fontSize: '.75rem',
                                    color: '#495057',
                                    fontWeight: 500,
                                  }}
                                >
                                  {statusDescription || 'No status available'}
                                </Typography>
                              </Grid>
                              {lastLocation && (
                                <Grid item>
                                  <Chip
                                    size='small'
                                    label={lastLocation || '-'}
                                    style={{
                                      backgroundColor: '#e9ecef',
                                      color: '#495057',
                                      height: '20px',
                                      fontSize: '.7rem',
                                      fontWeight: 500,
                                    }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Paper>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems='center' spacing={1}>
                            <Grid item>
                              <Typography
                                variant='caption'
                                style={{
                                  color: '#6c757d',
                                  fontSize: '.7rem',
                                }}
                              >
                                Last checked {lastChecked ? lastChecked : 'n/a'}
                              </Typography>
                            </Grid>
                            {order.inbound_shipment_activity && !order.inbound_shipment_activity.shipped && (
                              <Grid item>
                                <Button
                                  onClick={e => {
                                    e.stopPropagation();
                                    sendInboundReminder(order);
                                  }}
                                  size='small'
                                  style={{
                                    backgroundColor: '#17a2b8',
                                    color: '#fff',
                                    height: '20px',
                                    padding: '0 8px',
                                    fontSize: '.7rem',
                                    minWidth: '110px',
                                    textTransform: 'none',
                                  }}
                                >
                                  Send a reminder
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography variant='body2' style={{ color: '#6c757d' }}>
                        No shipment data
                      </Typography>
                    ),
                    order: order,
                  };
                });

                return (
                  <>
                    <AccordianTable
                      title={`${rows.length} Orders`}
                      headers={headers}
                      rows={rows}
                      actions={getTableActions(data.orders)}
                      search={search}
                      defaultOrder={defaultOrder}
                      defaultOrderBy={defaultOrderBy}
                      order={order}
                      orderBy={orderBy}
                      tablePage={tablePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      setSearch={setSearch}
                      setOrder={setOrder}
                      setOrderBy={setOrderBy}
                      setTablePage={setTablePage}
                      setRowsPerPage={setRowsPerPage}
                      setExpandedRowId={setExpandedRowId}
                      className={cls.table}
                    >
                      {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                        .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                        .map(row => {
                          return (
                            <AccordianRow
                              key={`order-index-${row.order.id}`}
                              rowId={row.order.id}
                              expandedRowId={expandedRowId}
                              setExpandedRowId={setExpandedRowId}
                              columns={[
                                { align: 'left', value: row.ID },
                                { align: 'left', value: row.EVENT_NAME },
                                { align: 'left', value: row.STATUS },
                                { align: 'left', value: row.EVENT_DATE },
                                { align: 'left', value: row.DAYS_LATE },
                                { align: 'left', value: row.RETURN_SHIP_DATE },
                                { align: 'left', value: row.RETURN_DATE },
                                { align: 'left', value: row.INBOUND_SHIPMENT_STATUS },
                              ]}
                              actions={getRowActions(row.order)}
                              onClick={() => {
                                goToOrder(row.order.id);
                              }}
                              className={!row.order.disputed ? cls.row : cls.rowDisputed}
                            >
                              <div />
                            </AccordianRow>
                          );
                        })}
                    </AccordianTable>
                  </>
                );
              } else
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>NO ORDERS FOUND</Typography>
                  </div>
                );
            }}
          </Subscription>
        </Container>
      </div>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowDisputed: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowTxt: {
    color: 'inherit',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  rowIcon: {
    display: 'inline',
    verticalAlign: '-25%',
    marginRight: theme.spacing(0.5),
    color: '#inherit',
    cursor: 'pointer',
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  shipmentStatusBar: {
    height: 'inherit',
    padding: '0 5px',
    color: '#a1a6aa',
    width: 'max-content',
    border: '1px solid #a1a6aa',
    borderRight: '0',
    borderRadius: '.25rem 0 0 .25rem',
  },
  remindButton: {
    backgroundColor: '#cb444a',
    height: 'inherit',
    borderRadius: '0 .25rem  .25rem 0',
    color: '#fff',
    fontSize: '75%',
  },
  statusChip: {
    fontWeight: '600',
    color: 'white',
    borderRadius: '.25rem',
    height: 'min-content',
    padding: '4px',
  },
  table: {
    marginTop: theme.spacing(2),
  },
}));

const GET_ORDERS = () => gql`
  subscription returningOrders {
    orders(
      order_by: {return_date: asc},
      where: {
        active: {_eq: true},
        status: {_in: ["Outbound", "Delivered", "Packing", "Inbound", "Returned", "Claim"]},
        event_date: {_lt: "${new Date().toISOString().substring(0, 10)}"}
      }
    ) {
      active
      id
      status
      event_name
      first_name
      last_name
      email
      ship_date
      arrival_date
      event_date
      return_ship_date
      return_date
      outbound_tracking
      shipping_address1
      shipping_address2
      shipping_city
      shipping_state
      shipping_zip
      transit_days
      inbound_shipment_activity {
        ...ShipmentActivity
      }
    }
  }
  ${fragments.shipmentActivity}
`;

export default withRouter(InboundReport);
