import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  Typography,
  CardMedia,
  CardActionArea,
  Icon,
  IconButton,
  Box,
  Chip,
  Fade,
} from '@material-ui/core';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius * 2,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
    },
  },
  mediaContainer: {
    position: 'relative',
    height: 360,
    overflow: 'hidden',
  },
  media: {
    height: '100%',
    backgroundSize: 'cover',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1,
  },
  viewPrompt: {
    color: theme.palette.common.white,
    fontWeight: 500,
    textAlign: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  titleContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 2,
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
  },
  title: {
    color: theme.palette.common.white,
    fontWeight: 600,
    textShadow: '1px 1px 3px rgba(0,0,0,0.7)',
  },
  photoCount: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  messageContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: theme.spacing(2),
    zIndex: 2,
  },
  message: {
    width: '100%',
    objectFit: 'contain',
    maxHeight: '150px',
  },
  cardActions: {
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  actionButton: {
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}));

/**
 * A showcase card component for displaying photo sessions
 */
function ShowcaseCard(props) {
  const { photos = [], message, strip, title = '', caption = '' } = props;
  const cls = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxPhotos, setLightboxPhotos] = useState([]);

  useEffect(() => {
    if (!photos) return;
    let tempList = photos.map(photo => photo && photo.full_url);
    if (strip) {
      tempList.unshift(strip.full_url);
    }
    setLightboxPhotos(tempList);
  }, [photos, strip]);

  const showLightbox = () => {
    setPhotoIndex(0);
    setIsOpen(true);
  };

  const toggleMessage = () => {
    setShowMessage(!showMessage);
  };

  const imgSrc = photos.length > 0 ? photos[0].full_url : strip && strip.full_url;

  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={lightboxPhotos[photoIndex]}
          nextSrc={lightboxPhotos[(photoIndex + 1) % lightboxPhotos.length]}
          prevSrc={lightboxPhotos[(photoIndex + lightboxPhotos.length - 1) % lightboxPhotos.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + lightboxPhotos.length - 1) % lightboxPhotos.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % lightboxPhotos.length)}
          reactModalStyle={{
            overlay: {
              zIndex: 1000000,
            },
            content: {
              zIndex: 1000000,
            },
          }}
        />
      )}
      <Card
        className={cls.card}
        raised={isHovering}
        onMouseOver={() => {
          setIsHovering(true);
        }}
        onMouseOut={() => {
          setIsHovering(false);
        }}
      >
        <CardActionArea onClick={showLightbox}>
          <Box className={cls.mediaContainer}>
            {imgSrc && <CardMedia className={cls.media} image={imgSrc} title={title} />}

            <Fade in={isHovering}>
              <Box className={cls.overlay}>
                <Typography variant='body2' className={cls.viewPrompt}>
                  Click to view all photos
                </Typography>
              </Box>
            </Fade>

            {message && showMessage && (
              <Box className={cls.messageContainer}>
                <img className={cls.message} src={message.full_url} alt='Message' />
              </Box>
            )}

            <Box className={cls.titleContainer}>
              {title && (
                <Typography variant='subtitle1' className={cls.title}>
                  {title}
                </Typography>
              )}
              {photos.length > 0 && (
                <Chip
                  size='small'
                  label={`${photos.length} photo${photos.length !== 1 ? 's' : ''}`}
                  className={cls.photoCount}
                />
              )}
            </Box>
          </Box>
        </CardActionArea>

        <CardActions className={cls.cardActions}>
          <Box display='flex' alignItems='center'>
            <IconButton size='small' aria-label='Photos' onClick={showLightbox} className={cls.actionButton}>
              <Icon>{`filter${photos.length ? `_${photos.length}` : ''}`}</Icon>
              <Typography variant='caption' style={{ marginLeft: '4px' }}>
                View All
              </Typography>
            </IconButton>

            {message && (
              <IconButton
                size='small'
                aria-label='Message'
                onClick={toggleMessage}
                className={cls.actionButton}
                color={showMessage ? 'primary' : 'default'}
              >
                <Icon>gesture</Icon>
                <Typography variant='caption' style={{ marginLeft: '4px' }}>
                  {showMessage ? 'Hide Message' : 'Show Message'}
                </Typography>
              </IconButton>
            )}
          </Box>
        </CardActions>
      </Card>
    </>
  );
}

ShowcaseCard.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      full_url: PropTypes.string.isRequired,
    })
  ),
  message: PropTypes.shape({
    full_url: PropTypes.string.isRequired,
  }),
  strip: PropTypes.shape({
    full_url: PropTypes.string.isRequired,
  }),
  title: PropTypes.string,
  caption: PropTypes.string,
};

export default ShowcaseCard;